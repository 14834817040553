import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Flex, Box } from "../components/FlexBox";

import ImgTextSection from "../components/sections/ImgTextSection";
import MidTextSection from "../components/sections/MidTextSection";
import { Paragraph } from "../components/parts/typography";

export default function Home({ data }) {
    const Img1 = data.homeImage.childImageSharp.fluid;
    const Img2 = data.homeImage2.childImageSharp.fluid;
    const Img3 = data.homeImage3.childImageSharp.fluid;

    return (
        <Layout>
            <Flex
                data-name="home"
                flexDirection="column"
                px="2px"
                mx="-20px"
                width="calc(100% + 40px)"
            >
                <Box mb={["34px", "84px"]}>
                    <ImgTextSection
                        order="1"
                        fluid={Img1}
                        headline="Architektur"
                        textAlign={["left", "right"]}
                        flexTextAlign={["flex-start", "flex-end"]}
                    >
                        <Paragraph>
                            Als Architekturbüro mit Sitz in Berlin unterstützen
                            wir mit Planungs- und Beratungsleistungen eine
                            Vielzahl von Bauherren, Projektentwicklern und
                            Immobilieneigentümern bei der Realisierung und
                            Betreuung unterschiedlichster Bauvorhaben und
                            Gebäudearten.
                            <br />
                            Bei der ersten Betrachtung unserer Aufgaben ist ein
                            individuell auf das Projekt angepasstes
                            Leistungsbild die gemeinsame Basis.
                        </Paragraph>
                    </ImgTextSection>
                </Box>
                <ImgTextSection
                    order={["1", "2"]}
                    fluid={Img2}
                    headline="Planung und Projektabwicklung"
                    textAlign="left"
                    flexTextAlign="flex-start"
                >
                    <Paragraph>
                        Ein Schwerpunkt unserer Leistungen ist die Fertigung
                        einer ausführungsreifen Gebäudeplanung, dabei stützt
                        sich unsere fachliche Kompetenz auf langjährige
                        Erfahrungen mit komplexen Projekten. Neben der Ästhetik
                        und Qualitätsanspruch behalten wir die
                        Wirtschaftlichkeit für unsere Auftraggeberin darüber im
                        Focus.
                        <br />
                        Planerische Maßnahmen im Bereich ressourcenschonender
                        Technologien und Materialien können zu einem weiteren
                        Kriterium der Nachhaltigkeit bei der Umsetzung der
                        Vorhaben beitragen. Als von der Architektenkammer
                        zertifizierter Sachverständiger für barrierefreie
                        Gebäudeplanung sind wir auch in diesem Fachgebiet ein
                        kompetenter Ansprechpartner.
                    </Paragraph>
                </ImgTextSection>
            </Flex>
            <Flex justifyContent="center" mt={["50px", "137px"]}>
                <Flex maxWidth={["100%", "872px"]} flexDirection="column">
                    <Box mb={["86px", "145px"]}>
                        <MidTextSection text="" headline="UNTERNEHMEN">
                            <Paragraph>
                                IGK Berlin MBH wurde 1995 in Berlin als
                                Ingenieurbüro im Bauwesen von dem Architekten
                                Klaus Kaiser gegründet. Nunmehr kann die
                                Architekten- und Ingenieurgesellschaft auf eine
                                Vielzahl von Projekten an zahlreichen Standorten
                                zurückblicken und die erlangte Kompetenz für ihr
                                Projekt einsetzen. So wird Professionalität in
                                der Methodik, Zuverlässigkeit im Prozess sowie
                                Qualität und Beständigkeit im Ergebnis
                                gewährleistet.
                            </Paragraph>
                        </MidTextSection>
                    </Box>
                    <Box ml="-18px" mr="-18px">
                        <ImgTextSection
                            fontSize={["19px", "22px"]}
                            order={["2"]}
                            fluid={Img3}
                            headline="WOHNUNGS - UND GEWERBEBAU"
                            headlineFontWeight={[600]}
                            textAlign="left"
                            link="Projekte"
                            linkUrl="/projekte"
                        >
                            <Paragraph>
                                Ob Neu- oder Altbau
                                <br />
                                Mit einem hohen Qualitätsanspruch & Kreativität
                                helfen wir Ihnen dabei Ihre Vision umzusetzen.
                            </Paragraph>
                        </ImgTextSection>
                    </Box>
                </Flex>
            </Flex>
        </Layout>
    );
}

export const ImageQuery = graphql`
    query {
        homeImage: file(relativePath: { eq: "homepage/1_home.jpg" }) {
            childImageSharp {
                fluid(webpQuality: 70) {
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        homeImage2: file(relativePath: { eq: "homepage/2_home.jpg" }) {
            childImageSharp {
                fluid(webpQuality: 70) {
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        homeImage3: file(
            relativePath: { eq: "homepage/3_insel_eiswerder.jpg" }
        ) {
            childImageSharp {
                fluid(webpQuality: 70) {
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(placeholder: BLURRED)
            }
        }
    }
`;
